<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-16 22:12:44
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-20 21:59:17
-->
<template>
  <div class="page-box">
    <div class="title"></div>
    <div class="list">
      <div
        class="menu flex-x center between"
        v-for="(item, index) in menu"
        :key="index"
        @click="$router.push({ name: item.routerName, query: item.query })"
      >
        <div class="menu__img img-box">
          <img :src="item.icon" alt="" />
        </div>
        <div class="detail grow">
          <div class="num">{{ item.num }}</div>
          <div class="context">{{ item.name }}</div>
        </div>
        <div class="arrow"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderStatus } from "../../../utils/api/workOrder";
export default {
  data() {
    return {
      menu: [
        {
          name: "待付款",
          icon: require("../../../assets/images/review/wait_pay.png"),
          query: {
            status: 5,
          },
          routerName: "ReviewMineOrderList",
          num: 0,
        },
        {
          name: "待派单",
          icon: require("../../../assets/images/review/wait_dispatch.png"),
          query: {
            status: 1,
          },
          routerName: "ReviewMineOrderList",
          num: 0,
        },
        {
          name: "草稿箱",
          icon: require("../../../assets/images/review/draftbox.png"),
          query: {
            status: 7,
          },
          routerName: "ReviewDraftBox",
          num: 0,
        },
        {
          name: "已完成",
          icon: require("../../../assets/images/review/complete.png"),
          query: {
            status: 6,
          },
          routerName: "ReviewMineOrderList",
          num: 0,
        },
      ],
    };
  },

  created() {
    orderStatus().then((res) => {
      let statusList = res.data.data.list;
      let menu = JSON.parse(JSON.stringify(this.menu));

      for (let i = 0; i < statusList.length; i++) {
        for (let j = 0; j < menu.length; j++) {
          if (statusList[i].status === menu[j].query.status)
            menu[j].num = statusList[i].number;
        }
      }

      this.menu = menu;
    });
  },
};
</script>

<style src="@/assets/css/review/mine.css" scoped>
</style>