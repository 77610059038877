/*
 * @Descripttion: 本人工单接口
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-10 23:34:10
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-17 22:42:53
 */
import request from "../request"

// 提交工单
export const submit = function (data) {
    return request.post('/work/submit', data)
}

// 提交到草稿箱
export const submitDraftBox = function (data) {
    return request.post('/work/submitDraftBox', data)
}

// 草稿箱列表
export const draftBoxList = function (data) {
    return request.post('/work/getDraftBoxList', data)
}

// 删除草稿
export const delDraftBox = function (data) {
    return request.post('/work/deleteDraftBox', data)
}

// 工单列表
export const orderList = function (data) {
    return request.get('/work/getWorkList', {
        params: data
    })
}

// 工单详情
export const orderDetail = function (data) {
    return request.post('/work/getWorkDetail', data)
}

// 工单状态
export const orderStatus = function (data) {
    return request.get('/work/getSubmitUserStatusList', data)
}

// 删除工单
export const delOrders = function (data) {
    return request.post('/work/delete', data)
}